import React from 'react';
import Img from 'gatsby-image';
import { graphql, Link, useStaticQuery } from 'gatsby';
import FeatureSectionLight from '../components/featureSectionLight';
import SubLayout from '../layouts/subLayout';
import AllServices from '../components/allServices';
import FeatureCardsSection from '../components/featureCardsSection';
import TechBox from '../components/techBox';

import CloudIcon from '../../assets/svg/cloud_m.svg';
import NginxIcon from '../../assets/svg/nginx-icon.svg';
import PrometheusIcon from '../../assets/svg/prometheus.svg';
import KubernetesIcon from '../../assets/svg/kubernetes-opt.svg';
import DockerIcon from '../../assets/svg/docker-plain.svg';
import GithubIcon from '../../assets/svg/github-original.svg';
import TerraformIcon from '../../assets/svg/terraform.svg';
import DevOpsConcept from '../../assets/svg/devops-loop.svg';
import ServerIcon from '../../assets/svg/server.svg';
import CICDIcon from '../../assets/svg/cicd.svg';
import MonitoringIcon from '../../assets/svg/monitoring.svg';
import SEO from '../components/seo';

const DevOpsPage = () => {
  const data = useStaticQuery(graphql`
        query DevOpsImage {
            kompleksowaDO: file(
                relativePath: { eq: "kompleksowa-usluga.png" }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
  return (
    <SubLayout
      title={(
        <>
          <span>DevOps</span>
          {' '}
          Services
        </>
              )}
      subtitle="Between software development and operations"
      img="/img/serwery-devops.jpg"
    >
      <SEO title="DevOps and SysAdmin Services - iqcode" description="iqcode is a Software House based in Warsaw, Poland offering DevOps services such as server administration, Kubernetes implementation and CI/CD pipelines." />
      <div style={{ boxShadow: '3px 3px 30px #00000070' }}>
        <FeatureSectionLight
          title={(
            <>
              <span>DevOps</span>
              {' '}
              and SysAdmin Services
            </>
                      )}
          img={<DevOpsConcept style={{ width: '95%', height: 'auto', margin: '0 auto' }} />}
          desc={(
            <>
              <p>
                DevOps is a practice that aims to improve the cooperation between teams responsible
                for development and operations in software projects.
              </p>
              <p>
                As a software house, we offer DevOps services including
                maintaining applications in production environments width
                strict SLA requirements, configuring CI/CD pipelines and
                consulting in the area of software engineering best practices.
              </p>
              <p>
                We deploy the majority of our DevOps solutions in Kubernetes,
                which is a modern distributed systems environment allowing
                for orchestration of contenerized applications ensuring
                their high availability.
              </p>
            </>
                      )}
          bottom={(
            <TechBox
              fullWidth
              technologies={[
                {
                  icon: <ServerIcon />,
                  name: 'Server Administration',
                },
                {
                  icon: <CICDIcon />,
                  name: 'Continuos Integration and Deployment',
                },
                {
                  icon: <CloudIcon />,
                  name: 'Cloud Migration',
                },
                {
                  icon: <MonitoringIcon />,
                  name: 'Application Monitoring',
                },
              ]}
            />
                    )}
        />
      </div>
      <div className="section" id="aplikacje-mobilne">
        <div className="promo overlay-holder z-depth-3">
          <div className="diagonal-overlay-light" />
          <div className="rel">
            <h3>
              Considering an IT
              {' '}
              <span>Project</span>
              ?
            </h3>
            <Link className="btn" to="/contact/">
              Let's talk
            </Link>
          </div>
        </div>
        <FeatureCardsSection
          id="backend"
          title={(
            <>
              <span>DevOps</span>
              {' '}
              Technologies
            </>
                      )}
          subtitle="Our DevOps tech stack"
          cards={[
            {
              title: 'Kubernetes',
              desc:
                                'Distributed systems environment which allows for running contenerized applications ensuring resilience to faults of the underlying infrastructure.',
              icon: <KubernetesIcon />,
            },
            {
              title: 'Docker',
              desc:
                                'Virtualization ecosystem used for wrapping applications with their configuration in portable containers.',
              icon: <DockerIcon />,
            },
            {
              title: 'Prometheus',
              desc:
                                'Innovative monitoring suite responsible for collecting application metrics in a distributed environment.',
              icon: <PrometheusIcon />,
            },
            {
              title: 'Nginx',
              desc:
                                'Open-source web and reverse-proxy server commonly used as an ingress controller in Kubernetes clusters.',
              icon: <NginxIcon />,
            },
            {
              title: 'GitHub',
              desc:
                                'Modern source code repository hosting platform which allows for creation of continuos integration and deployment pipelines.',
              icon: <GithubIcon />,
            },
            {
              title: 'Terraform',
              desc:
                                'A tool which allows for automatic and reliable provisioning of cloud resources defined declaratively as code.',
              icon: <TerraformIcon />,
            },
          ]}
        />
        <div className="promo overlay-holder z-depth-3">
          <div className="diagonal-overlay-light" />
          <div className="rel">
            <h3>
              Looking for
              {' '}
              <span>IT Consulting</span>
              ?
            </h3>
            <Link className="btn" to="/consulting-it/">
              Check out our offer
            </Link>
          </div>
        </div>
        <FeatureSectionLight
          title={(
            <>
              <span>All-rounded </span>
              Software projects delivery
            </>
                      )}
          mb0
          img={(
            <div style={{ padding: '10%' }}>
              <Img
                alt="kompleksowe usługi devops"
                fluid={data.kompleksowaDO.childImageSharp.fluid}
                imgStyle={{ width: 'auto' }}
              />
            </div>
                      )}
          desc={(
            <>
              <p>
                Aside from DevOps services that we offer in conjunction
                with creation and maintenance of our IT projects, we
                also implement CI/CD pipelines for development teams
                of other software houses, perform migrations from on-premise
                to public cloud and offer Linux server administration services.
              </p>
              <p>
                DevOps services are not everything that we have to offer. Our wide variety of mobile, web and server-side technologies allows us to deliver complex IT projects cross-cutting numerous software engineering areas devoid of technical debt.
              </p>
            </>
                      )}
          link="Let's talk"
          to="/contact/"
        />

        <AllServices />
      </div>
    </SubLayout>
  );
};

export default DevOpsPage;
